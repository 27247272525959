
export default {
  data() {
    return {
      // elmentList: [
      //   {
      //     pcElementId: 441,
      //     phoneElementId:447,
      //     descList: [
      //       {
      //         descName: "防爆履带机器人-D071",
      //         sort: 1,
      //       },
      //       {
      //         descName: "Ex db IIB T4 Gb",
      //         sort: 2,
      //       },
      //       {
      //         descName: "IP65",
      //         sort: 3,
      //       },
      //       {
      //         descName: "0-1.4m/s",
      //         sort: 4,
      //       },
      //       {
      //         descName: "≤3h",
      //         sort: 5,
      //       },
      //       {
      //         descName: "防爆等级",
      //         sort: 6,
      //       },
      //       {
      //         descName: "防护等级",
      //         sort: 7,
      //       },
      //       {
      //         descName: "行走速度",
      //         sort: 8,
      //       },
      //       {
      //         descName: "工况续航",
      //         sort: 9,
      //       },
      //     ],
      //     type: "image",
      //     sort: 1,
      //   },
      //   {
      //     pcElementId: 442,
      //     phoneElementId: 448,
      //     descList: [
      //       {
      //         descName: "防爆履带机器人-D071",
      //         sort: 1,
      //       },
      //       {
      //         descName:
      //           "采用高效履带系统，较轮式结构更加适应更复杂的路面情况，凭借较大的接地比压以及特殊的履带纹理设计，能够满足极寒环境、雨雪交加、路面湿滑、崎岖山地等复杂使用场景需求，具备卓越的多气候、全地形适应能力。内部传感、控制采用模块化设计，彼此独立又能高效协同，能够始终维持高度稳定可靠的运行状态。",
      //         sort: 2,
      //       },
      //     ],
      //     type: "image",
      //     sort: 2,
      //   },
      //   {
      //     pcElementId: 443,
      //     phoneElementId: 449,
      //     descList: [
      //       {
      //         descName: "应用场景",
      //         sort: 1,
      //       },
      //       {
      //         descName:
      //           "防爆履带机器人-D071可为油气开采业、管道运输业、油煤燃料加工业、燃气产供业、石油化学品制造业、发电业、输配供电业等广大企业厂矿场站提供基于安全生产的智能巡检方案。适用于各类油、气、田、管、罐、线、缆等易燃易爆、有毒有害的室外及室内智能巡检业务场景。",
      //         sort: 2,
      //       },
      //     ],
      //     type: "image",
      //     sort: 3,
      //   },
      //   {
      //     pcElementId: '',
      //     phoneElementId: '',
      //     descList: [
      //       {
      //         descName: "基本功能",
      //         sort: 1,
      //       },
      //       {
      //         descName: "遥控器控制",
      //         sort: 2,
      //       },
      //       {
      //         descName: "急停功能",
      //         sort: 3,
      //       },
      //       {
      //         descName: "无人自主巡检",
      //         sort: 4,
      //       },
      //       {
      //         descName: "智能避障功能",
      //         sort: 5,
      //       },
      //       {
      //         descName: "补光灯功能",
      //         sort: 6,
      //       },
      //       {
      //         descName: "智能充电功能",
      //         sort: 7,
      //       },
      //       {
      //         descName: "充电保护功能",
      //         sort: 8,
      //       },
      //       {
      //         descName: "网络通讯、远程控制",
      //         sort: 9,
      //       },
      //       {
      //         descName: "定点巡检功能",
      //         sort: 10,
      //       },
      //       {
      //         descName: "环境气体监测",
      //         sort: 11,
      //       },
      //       {
      //         descName: "视频存储监控",
      //         sort: 12,
      //       },
      //       {
      //         descName: "语音播报",
      //         sort: 13,
      //       },
      //       {
      //         descName: "离线巡检",
      //         sort: 14,
      //       },
      //       {
      //         descName: "双向语音",
      //         sort: 15,
      //       },
      //     ],
      //     type: "image",
      //     sort: 4,
      //   },
      //   {
      //     pcElementId:444,
      //     phoneElementId: 450,
      //     descList: [
      //       {
      //         descName: "选配功能",
      //         sort: 1,
      //       },
      //       {
      //         descName: "自动控制充电房",
      //         sort: 2,
      //       },
      //       {
      //         descName: "甲烷激光遥测",
      //         sort: 3,
      //       },
      //       {
      //         descName: "火焰探测功能",
      //         sort: 4,
      //       },
      //       {
      //         descName: "气象环境采集功能",
      //         sort: 5,
      //       }
      //     ],
      //     type: "image",
      //     sort: 5,
      //   },
     
      //   {
      //     pcElementId: 445,
      //     phoneElementId: 450,
      //     descList: [
      //       {
      //         descName: "防爆履带机器人-D071",
      //         sort: 0,
      //       },
      //       {
      //         descName: "规格参数",
      //         sort: 1,
      //       },
      //       {
      //         descName: "外形尺寸(长×宽×高)",
      //         sort: 2,
      //       },
      //       {
      //         descName: "防爆等级",
      //         sort: 3,
      //       },
      //       {
      //         descName: "驱动方式",
      //         sort: 4,
      //       },
      //       {
      //         descName: "爬坡能力",
      //         sort: 5,
      //       },
      //       {
      //         descName: "越障能力",
      //         sort: 6,
      //       },
      //       {
      //         descName: "主导航方式",
      //         sort: 7,
      //       },
      //       {
      //         descName: "整备质量",
      //         sort: 8,
      //       },
      //       {
      //         descName: "防护等级",
      //         sort: 9,
      //       },
      //       {
      //         descName: "行走速度",
      //         sort: 10,
      //       },
      //       {
      //         descName: "越障能力",
      //         sort: 11,
      //       },
      //       {
      //         descName: "工况续航",
      //         sort: 12,
      //       },
      //       {
      //         descName: "重复导航定位精度",
      //         sort: 13,
      //       },
      //       {
      //         descName: "≤1420mm×900mm×1120mm",
      //         sort: 14,
      //       },
      //       {
      //         descName: "Ex db IIB T4 Gb",
      //         sort: 15,
      //       },
      //       {
      //         descName: "履带式",
      //         sort: 16,
      //       },
      //       {
      //         descName: "25°",
      //         sort: 17,
      //       },
      //       {
      //         descName: "100mm",
      //         sort: 18,
      //       },
      //       {
      //         descName: "SLAM激光导航和SLAM激光定位",
      //         sort: 19,
      //       },
      //       {
      //         descName: "≤550kg",
      //         sort: 20,
      //       },
      //       {
      //         descName: "IP66",
      //         sort: 21,
      //       },
      //       {
      //         descName: "0-1.4m/s，速度可调",
      //         sort: 22,
      //       },
      //       {
      //         descName: "120mm",
      //         sort: 23,
      //       },
      //       {
      //         descName: "≤3h",
      //         sort: 24,
      //       },
      //       {
      //         descName: "≤±5cm",
      //         sort: 25,
      //       },
      //     ],
      //     type: "image",
      //     sort: 6,
      //   },
      //   {
      //     pcElementId:446,
      //     phoneElementId:451,
      //     descList: [
      //       {
      //         descName: "防爆履带机器人-D071",
      //         sort: 1,
      //       },
      //       {
      //         descName: "履带式强适应，复杂地形更安全",
      //         sort: 2,
      //       },
      //     ],
      //     type: "image",
      //     sort: 7,
      //   },
      // ],
      elmentList: [],
      aboutswiper: null,
    };
  },
  mounted() {
    this.$store.dispatch("pageset/getbannerIndex", 0);
    document.documentElement.style.setProperty("--var-primary-color", "#fff");
        document.documentElement.style.setProperty("--var-primary-color", "#fff");
    //      this.$store.dispatch("pageset/getSourceData", {
    //   createBy: "",
    //   updateBy: "",
    //   elmentList: this.elmentList,
    //   pageId: this.$route.query.pageId, //页面id
    //   pageName: this.$route.path,
    //   path: this.$route.path,
    //   remark: "",
    // });
    // 获取当前页面的配置信息
    this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
        this.elmentList = this.$store.state.pageset.sourceData.elmentList;
    });
  },
  methods: {
    retention() {
      this.$router.push("/reservation");
    },
    openPdf() {
        this.$router.push('/pdfView')
    },
  },
};
